<template>
  <v-container fluid>
    <v-row class="pt-3">
      <v-col v-if="loading" cols="12">
        <div
          class="d-flex text-center justify-center mt-10"
          style="width: 100%"
        >
          <div>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
      </v-col>
      <v-col v-if="errorMssg">
        <h3 class="error--text">{{ errorMssg }}</h3>
      </v-col>
      <v-col>
        <v-slide-x-reverse-transition mode="out-in">
          <div flat v-if="patient">
            <div class="d-md-flex justify-space-between align-end">
              <div class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="goBack"
                      color="secondary"
                      text
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Atrás</span>
                </v-tooltip>
                <div>
                  <h2 class="secondary--text">
                    {{ patient.firstName }} {{ patient.lastName }}
                  </h2>

                  <div class="d-flex">
                    <div>
                      <span class="subtitle-2" v-if="age">{{ age }}</span>
                      <span class="light_secondary--text subtitle-2" v-else
                        >Edad sin definir</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="mr-md-5 my-5 my-md-0">
                <AppointmentInProgress
                  ref="appointmentInProgressComponent"
                  :showStartBtn="true"
                  :showPatientBtn="false"
                  :clickStart="clickStartAppointment"
                  @appointment-end="handleAppointmentUpdated"
                  @appointment-created="handleAppointmentCreated"
                  @click-cobrar="handleClickPayment"
                  :hideDotsBtn="attending && attending.invoices[0]"
                />
              </div>
            </div>
            <div class="px-md-5">
              <v-divider class="mt-5"></v-divider>
              <div class="mb-8 d-flex">
                <v-tabs
                  v-model="tab"
                  icons-and-text
                  height="40"
                  color="secondary"
                  slider-color="secondary"
                >
                  <v-tab>
                    <div class="caption font-weight-medium d-flex align-center">
                      <div>Datos generales</div>
                    </div>
                  </v-tab>
                  <v-tab>
                    <div class="caption font-weight-medium d-flex align-center">
                      <div>Consultas</div>
                    </div>
                  </v-tab>
                  <v-tab v-if="currentUser.role === 'doctor'">
                    <div class="caption font-weight-medium d-flex align-center">
                      <div>historial Médico</div>
                    </div>
                  </v-tab>
                  <v-tab v-if="currentUser.role === 'doctor'">
                    <div class="caption font-weight-medium d-flex align-center">
                      <div>registrar en historial médico</div>
                    </div>
                  </v-tab>
                  <v-tab
                    v-if="
                      currentUser.role === 'doctor' &&
                      (currentUser.doctor.subscription === 'premium' ||
                        currentUser.doctor.subscription === 'pro')
                    "
                  >
                    <div class="caption font-weight-medium d-flex align-center">
                      <div>Archivos</div>
                    </div>
                  </v-tab>
                  <v-tab>
                    <div class="caption font-weight-medium d-flex align-center">
                      <div>Facturas</div>
                    </div>
                  </v-tab>
                </v-tabs>
              </div>

              <div v-show="tab === 0" class="pb-10">
                <v-btn
                  class="mb-6"
                  @click="showEditForm = true"
                  color="primary"
                  depressed
                  small
                >
                  <v-icon class="mr-1" small>mdi-square-edit-outline</v-icon>
                  modificar
                </v-btn>
                <PatientInfo :patient="patient" />
              </div>

              <PatientHistory
                v-show="tab === 2 || tab === 3"
                :display="tab === 2 ? 'read' : 'add'"
                :patient="patient"
                :medicalRegisterDates="medicalRegisterDates"
                ref="PatientHistoryRef"
              />

              <div v-show="tab === 1">
                <PatientAppointments
                  ref="patientAppointmentsRef"
                  :patientId="patientId"
                  :patient="patient"
                  :medicalRegisterDates="medicalRegisterDates"
                  @show-medical-items-by-date="handleShowMedicalItemsByDate"
                />
              </div>

              <div v-show="tab === 4">
                <PatientFiles :patientId="patientId" />
              </div>

              <div v-show="tab === 5">
                <PatientInvoices :patientId="patientId" :patient="patient" />
              </div>
            </div>
          </div>
        </v-slide-x-reverse-transition>

        <v-dialog
          v-if="patient"
          width="1200"
          v-model="showEditForm"
          @keydown.esc="showEditForm = false"
          persisten
        >
          <v-card class="pa-8 d-print-none">
            <p class="text-right">
              <v-icon @click="showEditForm = false" style="cursor: pointer">
                mdi-close
              </v-icon>
            </p>
            <PatientForm
              :patient="patient"
              title="Actualizar"
              @patient-updated="handlePatientUpdated"
              @on-cancel="showEditForm = false"
            />
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="showStartAppointmentDialog"
          width="1200"
          persistent
          @keydown.esc="showStartAppointmentDialog = false"
        >
          <v-card class="pa-sm-10 pa-5">
            <div class="text-right">
              <v-btn text icon @click="showStartAppointmentDialog = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </div>
            <InitAppointmentForm
              :appointmentsToPick="appointments"
              :formatDate="formatDate"
              :patientId="patientId"
              @row-click="startAppointment"
              @created="startNewAppointment"
            />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { calculateAge } from "@/helpers/common.js";
import PatientInfo from "../components/Patient/PatientInfo.vue";
import PatientForm from "../components/Patient/PatientForm.vue";
import PatientHistory from "../components/Patient/PatientHistory.vue";
import PatientAppointments from "../components/Patient/PatientAppointments.vue";
import PatientInvoices from "../components/Patient/PatientInvoices.vue";
import PatientFiles from "../components/Patient/PatientFiles.vue";
import AppointmentInProgress from "../components/Appointment/AppointmentInProgress.vue";
import InitAppointmentForm from "../components/Appointment/InitAppointmentForm.vue";

export default {
  name: "Patient",
  components: {
    PatientInfo,
    PatientForm,
    PatientHistory,
    PatientAppointments,
    PatientInvoices,
    PatientFiles,
    AppointmentInProgress,
    InitAppointmentForm,
  },
  data() {
    return {
      patientId: null,
      loading: false,
      loadingHistory: false,
      patient: null,
      showEditForm: false,
      errorMssg: "",
      tab: 0,
      showStartAppointmentDialog: false,
      appointments: [],
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "medicalHistory",
      "subForms",
      "doctorFormModules",
      "attending",
    ]),
    age() {
      return calculateAge(this.patient.bornDate);
    },
    allModules() {
      let temp = [];
      for (let module of this.subForms) {
        temp.push(module);
      }

      for (let module of this.doctorFormModules) {
        const customModule = this.getModuleTemplate(module);
        temp.push(customModule);
      }

      return temp;
    },
    medicalRegisterDates() {
      let dates = [];

      for (let module of this.allModules || []) {
        for (let tab of module?.tabs || []) {
          if (this.medicalHistory[tab.key]) {
            let items = this.medicalHistory[tab.key];
            for (let item of items) {
              const date = moment(item.date).format("DD/MM/YYYY");
              const dateExist = dates.some((e) => e === date);

              if (!dateExist) {
                dates.push(date);
              }
            }
          }
        }
      }

      // sort dates (descending or ascending)
      const datesSorted = dates.sort((a, b) =>
        this.datesOrder === "descending"
          ? moment(b, "DD/MM/YYYY").unix() - moment(a, "DD/MM/YYYY").unix()
          : moment(a, "DD/MM/YYYY").unix() - moment(b, "DD/MM/YYYY").unix()
      );

      return datesSorted.reverse();
    },
  },
  methods: {
    ...mapMutations(["setAlert", "resetMedicalHistory"]),
    ...mapActions([
      "fetchGeneralHistory",
      "fetchConsultHistory",
      "fetchPatientGeneral",
    ]),
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    clickStartAppointment() {
      this.appointments = this.$refs.patientAppointmentsRef?.getItems();
      this.showStartAppointmentDialog = true;
    },
    handleAppointmentUpdated(item) {
      this.$refs.patientAppointmentsRef?.handleAppointmentUpdated(item);
    },
    handleAppointmentCreated(item) {
      this.$refs.patientAppointmentsRef?.handleAppointmentCreated(item);
    },
    handleClickPayment(apointment) {
      this.$refs.patientAppointmentsRef.handleClickPayment(apointment);
    },
    startNewAppointment(appointment) {
      this.$refs.patientAppointmentsRef?.handleAppointmentCreated({
        ...appointment,
        patient: this.patient,
      });
      this.startAppointment({
        ...appointment,
        patient: this.patient,
      });
    },
    startAppointment(appointment) {
      this.showStartAppointmentDialog = false;
      this.$refs.appointmentInProgressComponent.startAttending({
        ...appointment,
        patient: this.patient,
      });
    },
    handleShowMedicalItemsByDate(date) {
      this.$refs.PatientHistoryRef.showMedicalItemsByDate(date);
    },
    getModuleTemplate(module) {
      const moduleCode = module.id.toString();
      return {
        code: moduleCode,
        description: module.description,
        icon: "",
        color: "secondary",
        tabs: [
          {
            text: module.description,
            description: module.description,
            single: module.description,
            editorTemplate: module.content,
            icon: "",
            value: module.description,
            key: moduleCode,
            componentNames: {
              form: "patient-general-form",
              record: "patient-general-info-item",
              print: "patient-general-print",
            },
            api: {
              delete: "/patientGeneral/",
            },
          },
        ],
      };
    },
    goBack() {
      // Go back one step in the history
      this.$router.go(-1);
    },
    handlePatientUpdated(patientUpdated) {
      this.patient = patientUpdated;
      this.showEditForm = false;
    },
    async fetchPatient() {
      try {
        this.loading = true;

        const id = this.$route?.params?.id;
        this.patientId = id;

        const { data } = await axios.get(`/api/patient/${id}`);
        this.patient = data.patient;
      } catch (error) {
        console.log(error);
        this.errorMssg = error.response.data.message;
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: this.errorMssg,
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchPatientHistory() {
      try {
        this.loadingHistory = true;
        const id = this.$route?.params?.id;

        this.resetMedicalHistory({
          allergies: [],
          diagnostics: [],
          families: [],
          gynecology: [],
          evaluations: [],
          medications: [],
          activities: [],
          toxicHabits: [],
          appointments: [],
          labResults: [],
          surgeries: [],
        });

        this.fetchGeneralHistory(id);
        this.fetchConsultHistory(id);
        this.fetchPatientGeneral(id);
      } catch (error) {
        console.log(error);
        this.errorMssg = error.response.data.message;
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: this.errorMssg,
        });
      } finally {
        this.loadingHistory = false;
      }
    },
  },
  async mounted() {
    // get patient data
    await this.fetchPatient();

    // get patient clinical data
    if (this.currentUser.role === "doctor") {
      this.fetchPatientHistory();
    }
  },
};
</script>
